var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pt-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            {
              staticClass: "text-center text-sm-right py-0",
              attrs: { cols: "12", sm: "6" },
            },
            [
              _c("p", { staticClass: "mb-2" }, [
                _vm._v("Scegli l’argomento che ti interessa:"),
              ]),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "text-center text-sm-left",
              attrs: { cols: "12", sm: "6" },
            },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.categories,
                  color: "AXABlue",
                  solo: "",
                  flat: "",
                  outlined: "",
                  "append-icon": "keyboard_arrow_down",
                },
                on: { change: _vm.tracciamento },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c("v-icon", { attrs: { color: "AXABlue" } }, [
                          _vm._v("keyboard_arrow_down"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.filterCategory,
                  callback: function ($$v) {
                    _vm.filterCategory = $$v
                  },
                  expression: "filterCategory",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-expansion-panels",
                {
                  attrs: { accordion: "" },
                  model: {
                    value: _vm.panel,
                    callback: function ($$v) {
                      _vm.panel = $$v
                    },
                    expression: "panel",
                  },
                },
                _vm._l(_vm.filtereditems, function (p) {
                  return _c(
                    "v-expansion-panel",
                    { key: _vm.questions.title },
                    [
                      _c(
                        "v-expansion-panel-header",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "actions",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "AXABlue" } },
                                      [_vm._v("keyboard_arrow_down")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_vm._v(_vm._s(p.title) + " ")]
                      ),
                      _c("v-expansion-panel-content", [
                        _c("p", {
                          staticClass: "mb-0 answer",
                          domProps: { innerHTML: _vm._s(p.description) },
                        }),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }