var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "bg-grayblue", attrs: { fluid: "" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center py-5", attrs: { cols: "12" } },
                    [_c("h1", [_vm._v("Dichiarazione di accessibilità")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "py-5", attrs: { cols: "12" } }, [
                _c("p", [
                  _vm._v(" Il "),
                  _c("strong", [_vm._v("Gruppo AXA Italia")]),
                  _vm._v(" continua il suo percorso di adeguamento dei "),
                  _c("strong", [_vm._v("servizi online")]),
                  _vm._v(" alle “"),
                  _c("strong", [
                    _vm._v("Linee Guida dell’accessibilità” di AGID"),
                  ]),
                  _vm._v(
                    ", per renderli accessibili e disponibili al maggior numero di utenti, senza discriminazioni e differenze, in linea con le specifiche WCAG 2.1 del World Wide Web Consortium (W3C). "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Il nostro fine è quello di adeguarci ai requisiti di accessibilità previsti dall’appendice A della norma UNI CEI EN 301549 con un’attenta e continua analisi di usabilità da applicare su tutti i canali a disposizione degli utenti online. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Lavoriamo per offrire a tutte le persone la stessa possibilità di accesso ai nostri servizi online. Per questo crediamo che il modo più efficace per continuare a migliorare i nostri servizi sia ascoltare la voce dei nostri clienti."
                  ),
                  _c("br"),
                  _vm._v(
                    " Raccogliamo i loro feedback, eseguiamo studi e test sulle nostre piattaforme digitali, per migliorare continuamente l’esperienza all’interno dell’ecosistema digitale. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " L’attenzione alla progettazione in ottica accessibile è un obiettivo che perseguiamo con una logica di continua evoluzione e con un approccio integrato. "
                  ),
                ]),
              ]),
              _c("v-col", { staticClass: "py-3", attrs: { cols: "12" } }, [
                _c("h2", [
                  _vm._v(
                    " Dichiarazione di accessibilità Quotatore Per Noi per i soggetti di cui all’art. 3 comma 1-bis della legge 9 gennaio 2004, n.4 "
                  ),
                ]),
                _c("p", [
                  _c("strong", [_vm._v("AXA Assicurazioni S.p.A.")]),
                  _vm._v(" e "),
                  _c("strong", [_vm._v("AXA MPS Assicurazioni Vita S.p.A.")]),
                  _vm._v(
                    " si impegnano a rendere il proprio sito web accessibile conformemente alla legge 9 gennaio 2004, n. 4. La presente dichiarazione di accessibilità si applica a "
                  ),
                  _c("a", { attrs: { href: _vm.originUrl } }, [
                    _vm._v(_vm._s(_vm.originUrlWithoutProtocol)),
                  ]),
                  _vm._v(". "),
                ]),
              ]),
              _c("v-col", { staticClass: "py-3", attrs: { cols: "12" } }, [
                _c("h3", [
                  _vm._v(" Stato di conformità: Parzialmente conforme "),
                ]),
                _c("p", [
                  _vm._v(" Il sito web "),
                  _c("a", { attrs: { href: _vm.originUrl } }, [
                    _vm._v(_vm._s(_vm.originUrlWithoutProtocol)),
                  ]),
                  _vm._v(
                    " è parzialmente conforme ai requisiti previsti dall'appendice A della norma "
                  ),
                  _c("strong", [_vm._v("UNI CEI EN 301549")]),
                  _vm._v(
                    " in ragione dei casi di non conformità elencati di seguito. "
                  ),
                ]),
              ]),
              _c("v-col", { staticClass: "py-3", attrs: { cols: "12" } }, [
                _c("h3", [_vm._v(" Contenuti non accessibili ")]),
                _c("p", [
                  _vm._v(
                    " I contenuti di seguito elencati non sono accessibili per il seguente motivo: "
                  ),
                ]),
                _c("ol", [
                  _c("li", [
                    _vm._v(" inosservanza della legge 9 gennaio 2004, n. 4. "),
                  ]),
                ]),
                _c("ul", [
                  _c("li", [
                    _c("strong", [_vm._v("Requisito: 10.1.1.1 (PDF)")]),
                    _vm._v(
                      " - Le immagini decorative non sono contrassegnate come artefatti mentre, per le immagini significative, il testo alternativo non è utilizzato nel modo corretto; "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Requisito: 10.2.4.2 (PDF)")]),
                    _vm._v(
                      " - Alcuni documenti non hanno titoli che ne descrivono l'argomento o la finalità; "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Requisito: 9.1.1.1")]),
                    _vm._v(
                      " - Alcuni contenuti non testuali presentati all'utente non hanno un'alternativa testuale equivalente che serva allo stesso scopo; sono presenti immagini decorative che non sono state contrassegnate come tali; "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Requisito: 9.1.3.1")]),
                    _vm._v(
                      " - In alcuni casi, informazioni, struttura o correlazioni trasmesse dalla presentazione delle pagine non possono essere determinate programmaticamente (o non sono disponibili tramite testo); "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Requisito: 9.1.4.10")]),
                    _vm._v(
                      " - Il contenuto che non richiede una rappresentazione in due dimensioni (come tabelle di dati o mappe) non si ridispone quando cambia la dimensione visualizzata dal programma utente; "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Requisito: 9.1.4.11")]),
                    _vm._v(
                      " - Per alcuni componenti essenziali, anche nei diversi stati, il contrasto colore rispetto agli elementi adiacenti non supera il rapporto di 3:1; "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Requisito: 9.1.4.3")]),
                    _vm._v(
                      " - La rappresentazione visiva del testo e di immagini contenenti testo non ha sempre il rapporto minimo di contrasto richiesto, salvo eccezioni previste dalla normativa (es. i logotipi); "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Requisito: 9.2.1.1")]),
                    _vm._v(
                      " – Alcuni elementi non possono essere utilizzabili tramite tastiera (o interfaccia con input analogo); "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Requisito: 9.2.4.1")]),
                    _vm._v(
                      " - Manca un meccanismo per saltare i blocchi di contenuto che si ripetono su più pagine Web; "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Requisito: 9.2.4.3")]),
                    _vm._v(
                      " - In alcune pagine Web che possono essere navigate in modo sequenziale e in cui la sequenza di navigazione influisce sul loro significato e sul loro funzionamento, alcuni oggetti che potrebbero ricevere il focus non lo ricevono con un ordine tale da conservarne il senso e l'operatività; "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Requisito: 9.2.4.6")]),
                    _vm._v(
                      " – Alcuni elementi non hanno nome accessibile e non chiariscono sufficientemente contenuti o funzionalità; "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Requisito: 9.2.4.7")]),
                    _vm._v(
                      " - Su alcuni elementi interattivi non è visibile l'indicatore del focus; "
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Requisito: 9.4.1.2")]),
                    _vm._v(
                      " - In alcuni componenti dell'interfaccia utente (es: campi data o pulsanti nascondi/mostra), nome, ruolo, stati, proprietà e valori non sono corretti o impostati o non è avvisato l'utente e le sue tecnologie assistive quando questi cambiano. "
                    ),
                  ]),
                ]),
              ]),
              _c("v-col", { staticClass: "py-3", attrs: { cols: "12" } }, [
                _c("h3", [
                  _vm._v(" Redazione della dichiarazione di accessibilità "),
                ]),
                _c("p", [
                  _vm._v(" La presente dichiarazione è stata redatta il "),
                  _c("strong", [_vm._v("23/09/2024")]),
                  _vm._v(". "),
                ]),
                _c("p", [
                  _vm._v(" La valutazione è stata "),
                  _c("strong", [_vm._v("effettuata da terzi")]),
                  _vm._v(
                    " tramite analisi oggettive e soggettive (vfr. l'articolo 3, paragrafo 1, della decisione di esecuzione UE 2018/1523 della Commissione. "
                  ),
                ]),
                _c("p", [
                  _vm._v(" La dichiarazione è stata riesaminata da ultimo il "),
                  _c("strong", [_vm._v("23/09/2024")]),
                  _vm._v(
                    ", rispettando la raccomandazione di riesaminare periodicamente (con frequenza almeno annuale) l'esattezza delle affermazioni contenute nella presente dichiarazione di accessibilità "
                  ),
                ]),
              ]),
              _c("v-col", { staticClass: "py-3", attrs: { cols: "12" } }, [
                _c("h3", [
                  _vm._v(
                    " Modalità di invio delle segnalazioni e recapiti del soggetto erogatore. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Per segnalazioni e assistenza in merito all’accessibilità di questo sito web è a disposizione degli utenti l’indirizzo email "
                  ),
                  _c("a", { attrs: { href: "mailto:accessibile@axa.it" } }, [
                    _vm._v("accessibile@axa.it"),
                  ]),
                  _vm._v(
                    ", è consigliabile indicare nell’oggetto della richiesta “accessibilità Quotatore Per Noi”."
                  ),
                  _c("br"),
                  _vm._v(
                    " Per presentare un reclamo formale visita la pagina "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.axa.it/informazioni-utili-e-contatti#contatti-richieste-assistenza",
                        target: "_blank",
                      },
                    },
                    [_vm._v("Contatti/ Reclami")]
                  ),
                ]),
              ]),
              _c("v-col", { staticClass: "py-3", attrs: { cols: "12" } }, [
                _c("h3", [
                  _vm._v(" Modalità di invio delle segnalazioni all’AgID "),
                ]),
                _c("p", [
                  _vm._v(
                    " In caso di risposta insoddisfacente o di mancata risposta, nel termine di trenta giorni dalla notifica o dalla richiesta, l’interessato può inoltrare una segnalazione ad AgID, tramite pec, al seguente indirizzo:"
                  ),
                  _c(
                    "a",
                    { attrs: { href: "mailto:protocollo@pec.agid.gov.it" } },
                    [_vm._v("protocollo@pec.agid.gov.it")]
                  ),
                ]),
              ]),
              _c("v-col", { staticClass: "py-3", attrs: { cols: "12" } }, [
                _c("h3", [_vm._v(" Informazioni sul sito ")]),
                _c("ul", [
                  _c("li", [
                    _vm._v("Data di pubblicazione del sito web: Ottobre 2019"),
                  ]),
                  _c("li", [_vm._v("Test di usabilità: no.")]),
                  _c("li", [
                    _vm._v("CMS utilizzato per il sito web: nessuno."),
                  ]),
                ]),
              ]),
              _c("v-col", { staticClass: "py-3", attrs: { cols: "12" } }, [
                _c("h3", [_vm._v(" Informazioni sulla struttura ")]),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "il numero di dipendenti con disabilità presenti nell’amministrazione: "
                    ),
                    _c("strong", [_vm._v("80")]),
                    _vm._v(
                      " (aggiornamento al 31/12/2021 e riferito a tutto il Gruppo AXA Italia – composizione e note societarie su axa.it). "
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Il numero di postazioni di lavoro per dipendenti con disabilità: "
                    ),
                    _c("strong", [_vm._v("3")]),
                    _vm._v(
                      " (aggiornamento al 31/12/2021 e riferito a tutto il Gruppo AXA Italia) "
                    ),
                  ]),
                ]),
              ]),
              _c("v-col", { staticClass: "py-3", attrs: { cols: "12" } }, [
                _c("p", [
                  _c("small", [
                    _vm._v(
                      " AXA Assicurazioni S.p.A. - Sede Legale e Direzione Generale: Corso Como 17, 20154 Milano - Italia Tel. (+39) 02 480841 - Fax (+39) 02 48084331 - PEC: axaassicurazioni@axa.legalmail.it Capitale Sociale € 232.535.335 interamente versato - Ufficio del Registro delle Imprese di Milano-Monza-Brianza-Lodi e C. F. n. 00902170018 - P. I.V.A. GRUPPO IVA AXA ITALIA n. 10534960967 - Numero R.E.A. della C.C.I.A.A. di Milano n. 1576311 - Direzione e coordinamento di AXA MEDITERRANEAN HOLDING SAU ai sensi dell’art. 2497 bis C.C. - Iscrizione Albo Imprese IVASS n. 1.00025 - Capogruppo del gruppo assicurativo AXA ITALIA iscritto all’Albo Gruppi IVASS con il n. 041 - Impresa autorizzata all’esercizio delle assicurazioni con Decreto del Ministero dell’industria, del commercio e dell’artigianato del 31 dicembre 1935 - (Gazzetta Ufficiale del 9 aprile 1936 n. 83) "
                    ),
                  ]),
                ]),
                _c("p", [
                  _c("small", [
                    _vm._v(
                      " AXA MPS Assicurazioni Vita S.p.A. - Sede Legale: Via Aldo Fabrizi n. 9, 00128 Roma – Italia Tel.: (+39) 06 50870.1, Fax: (+39) 06 50870295 - PEC: axampsvita@legalmail.it Capitale Sociale € 569.000.000 interamente versato - Codice Fiscale e numero iscrizione al Registro delle Imprese di Roma: 01075910586, P. I.V.A. GRUPPO IVA AXA ITALIA n. 10534960967, Numero R.E.A. della C.C.I.A.A. di Roma 383155 - Autorizzata all’esercizio delle Assicurazioni con Decreto del Ministero dell’Industria, del Commercio e dell’Artigianato del 24/7/1974 (G.U. 8/8/1974 n. 208). Compagnia iscritta alla Sez. I dell’Albo delle Imprese IVASS al n. 1.00046. La Società è soggetta all’attività di direzione e coordinamento di AXA Assicurazioni S.p.A. ed appartiene al Gruppo assicurativo AXA ITALIA, iscritto all’Albo dei Gruppi assicurativi con il n. 041. "
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }