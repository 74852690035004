var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-content",
    { staticClass: "page_home", attrs: { id: "accessibilita" } },
    [_c("Accessibilita_body")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }