<template>
  <div>
    <v-container fluid class="bg-grayblue">
      <v-container>
        <v-row>
          <v-col cols="12" class="text-center py-5">
            <h1>Dichiarazione di accessibilit&agrave;</h1>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" class="py-5">
          <p>
            Il <strong>Gruppo AXA Italia</strong> continua il suo percorso di adeguamento dei <strong>servizi
            online</strong> alle “<strong>Linee
            Guida dell’accessibilità” di AGID</strong>, per renderli accessibili e disponibili al maggior numero di
            utenti, senza discriminazioni
            e differenze, in linea con le specifiche WCAG 2.1 del World Wide Web Consortium (W3C).
          </p>
          <p>
            Il nostro fine è quello di adeguarci ai requisiti di accessibilità previsti dall’appendice A della norma UNI
            CEI EN 301549 con
            un’attenta e continua analisi di usabilità da applicare su tutti i canali a disposizione degli utenti
            online.
          </p>
          <p>
            Lavoriamo per offrire a tutte le persone la stessa possibilità di accesso ai nostri servizi online. Per
            questo crediamo che il
            modo più efficace per continuare a migliorare i nostri servizi sia ascoltare la voce dei nostri
            clienti.<br/>
            Raccogliamo i loro feedback, eseguiamo studi e test sulle nostre piattaforme digitali, per migliorare
            continuamente l’esperienza
            all’interno dell’ecosistema digitale.
          </p>
          <p>
            L’attenzione alla progettazione in ottica accessibile è un obiettivo che perseguiamo con una logica di
            continua evoluzione e con un approccio integrato.
          </p>
        </v-col>
        <v-col cols="12" class="py-3">
          <h2>
            Dichiarazione di accessibilità Quotatore Per Noi per i soggetti di cui all’art. 3 comma 1-bis della legge 9
            gennaio 2004, n.4
          </h2>
          <p>
            <strong>AXA Assicurazioni S.p.A.</strong> e <strong>AXA MPS Assicurazioni Vita S.p.A.</strong> si impegnano
            a rendere il proprio sito web accessibile conformemente alla legge 9 gennaio 2004, n. 4. La presente
            dichiarazione di accessibilità si applica a
            <a :href="originUrl">{{ originUrlWithoutProtocol }}</a>.
          </p>
        </v-col>
        <v-col cols="12" class="py-3">
          <h3>
            Stato di conformità: Parzialmente conforme
          </h3>
          <p>
            Il sito web
            <a :href="originUrl">{{ originUrlWithoutProtocol }}</a>
            è parzialmente conforme ai requisiti previsti dall'appendice A della norma <strong>UNI CEI EN
            301549</strong> in
            ragione dei casi di non conformità elencati di seguito.
          </p>
        </v-col>
        <v-col cols="12" class="py-3">
          <h3>
            Contenuti non accessibili
          </h3>
          <p>
            I contenuti di seguito elencati non sono accessibili per il seguente motivo:
          </p>
          <ol>
            <li>
              inosservanza della legge 9 gennaio 2004, n. 4.
            </li>
          </ol>
          <ul>
            <li><strong>Requisito: 10.1.1.1 (PDF)</strong> - Le immagini decorative non sono contrassegnate come
              artefatti mentre, per le immagini significative, il testo alternativo non è utilizzato nel modo corretto;
            </li>
            <li><strong>Requisito: 10.2.4.2 (PDF)</strong> - Alcuni documenti non hanno titoli che ne descrivono
              l'argomento o la finalità;
            </li>
            <li><strong>Requisito: 9.1.1.1</strong> - Alcuni contenuti non testuali presentati all'utente non hanno
              un'alternativa testuale equivalente che serva allo stesso scopo; sono presenti immagini decorative che non
              sono state contrassegnate come tali;
            </li>
            <li><strong>Requisito: 9.1.3.1</strong> - In alcuni casi, informazioni, struttura o correlazioni trasmesse
              dalla presentazione delle pagine non possono essere determinate programmaticamente (o non sono disponibili
              tramite testo);
            </li>
            <li><strong>Requisito: 9.1.4.10</strong> - Il contenuto che non richiede una rappresentazione in due
              dimensioni (come tabelle di dati o mappe) non si ridispone quando cambia la dimensione visualizzata dal
              programma utente;
            </li>
            <li><strong>Requisito: 9.1.4.11</strong> - Per alcuni componenti essenziali, anche nei diversi stati, il
              contrasto colore rispetto agli elementi adiacenti non supera il rapporto di 3:1;
            </li>
            <li><strong>Requisito: 9.1.4.3</strong> - La rappresentazione visiva del testo e di immagini contenenti
              testo non ha sempre il rapporto minimo di contrasto richiesto, salvo eccezioni previste dalla normativa
              (es. i logotipi);
            </li>
            <li><strong>Requisito: 9.2.1.1</strong> – Alcuni elementi non possono essere utilizzabili tramite tastiera
              (o interfaccia con input analogo);
            </li>
            <li><strong>Requisito: 9.2.4.1</strong> - Manca un meccanismo per saltare i blocchi di contenuto che si
              ripetono su più pagine Web;
            </li>
            <li><strong>Requisito: 9.2.4.3</strong> - In alcune pagine Web che possono essere navigate in modo
              sequenziale e in cui la sequenza di navigazione influisce sul loro significato e sul loro funzionamento,
              alcuni oggetti che potrebbero ricevere il focus non lo ricevono con un ordine tale da conservarne il senso
              e l'operatività;
            </li>
            <li><strong>Requisito: 9.2.4.6</strong> – Alcuni elementi non hanno nome accessibile e non chiariscono
              sufficientemente contenuti o funzionalità;
            </li>
            <li><strong>Requisito: 9.2.4.7</strong> - Su alcuni elementi interattivi non è visibile l'indicatore del
              focus;
            </li>
            <li><strong>Requisito: 9.4.1.2</strong> - In alcuni componenti dell'interfaccia utente (es: campi data o
              pulsanti nascondi/mostra), nome, ruolo, stati, proprietà e valori non sono corretti o impostati o non è
              avvisato l'utente e le sue tecnologie assistive quando questi cambiano.
            </li>
          </ul>

        </v-col>
        <v-col cols="12" class="py-3">
          <h3>
            Redazione della dichiarazione di accessibilità
          </h3>
          <p>
            La presente dichiarazione è stata redatta il <strong>23/09/2024</strong>.
          </p>
          <p>
            La valutazione è stata <strong>effettuata da terzi</strong> tramite analisi oggettive e soggettive (vfr.
            l'articolo 3, paragrafo 1,
            della decisione di esecuzione UE 2018/1523 della Commissione.
          </p>
          <p>
            La dichiarazione è stata riesaminata da ultimo il <strong>23/09/2024</strong>, rispettando la
            raccomandazione di
            riesaminare periodicamente (con frequenza almeno annuale) l'esattezza delle affermazioni
            contenute nella presente dichiarazione di accessibilità
          </p>
        </v-col>
        <v-col cols="12" class="py-3">
          <h3>
            Modalità di invio delle segnalazioni e recapiti del soggetto erogatore.
          </h3>
          <p>
            Per segnalazioni e assistenza in merito all’accessibilità di questo sito web è a disposizione degli utenti
            l’indirizzo email <a href="mailto:accessibile@axa.it">accessibile@axa.it</a>, è consigliabile indicare
            nell’oggetto della richiesta “accessibilità Quotatore Per Noi”.<br/>
            Per presentare un reclamo formale visita la pagina
            <a href="https://www.axa.it/informazioni-utili-e-contatti#contatti-richieste-assistenza" target="_blank">Contatti/
              Reclami</a>
          </p>
        </v-col>
        <v-col cols="12" class="py-3">
          <h3>
            Modalità di invio delle segnalazioni all’AgID
          </h3>
          <p>
            In caso di risposta insoddisfacente o di mancata risposta, nel termine di trenta giorni dalla notifica o
            dalla richiesta, l’interessato può inoltrare una segnalazione ad AgID, tramite pec, al seguente indirizzo:<a
              href="mailto:protocollo@pec.agid.gov.it">protocollo@pec.agid.gov.it</a>
          </p>
        </v-col>
        <v-col cols="12" class="py-3">
          <h3>
            Informazioni sul sito
          </h3>
          <ul>
            <li>Data di pubblicazione del sito web: Ottobre 2019</li>
            <li>Test di usabilità: no.</li>
            <li>CMS utilizzato per il sito web: nessuno.</li>
          </ul>
        </v-col>
        <v-col cols="12" class="py-3">
          <h3>
            Informazioni sulla struttura
          </h3>
          <ul>
            <li>il numero di dipendenti con disabilità presenti nell’amministrazione: <strong>80</strong> (aggiornamento
              al 31/12/2021 e riferito a tutto il Gruppo AXA Italia – composizione e note societarie su axa.it).
            </li>
            <li>Il numero di postazioni di lavoro per dipendenti con disabilità: <strong>3</strong> (aggiornamento al
              31/12/2021 e riferito a tutto il Gruppo AXA Italia)
            </li>
          </ul>
        </v-col>
        <v-col cols="12" class="py-3">
          <p>
            <small>
              AXA Assicurazioni S.p.A. - Sede Legale e Direzione Generale: Corso Como 17, 20154 Milano - Italia
              Tel. (+39) 02 480841 - Fax (+39) 02 48084331 - PEC: axaassicurazioni@axa.legalmail.it
              Capitale Sociale € 232.535.335 interamente versato - Ufficio del Registro delle Imprese di
              Milano-Monza-Brianza-Lodi e C. F. n. 00902170018 - P. I.V.A. GRUPPO IVA AXA ITALIA n. 10534960967 - Numero
              R.E.A. della C.C.I.A.A. di Milano n. 1576311 - Direzione e coordinamento di AXA MEDITERRANEAN HOLDING SAU
              ai sensi dell’art. 2497 bis C.C. -
              Iscrizione Albo Imprese IVASS n. 1.00025 - Capogruppo del gruppo assicurativo AXA ITALIA iscritto all’Albo
              Gruppi IVASS con il n. 041 -
              Impresa autorizzata all’esercizio delle assicurazioni con Decreto del Ministero dell’industria, del
              commercio e dell’artigianato del 31 dicembre 1935 - (Gazzetta Ufficiale del 9 aprile 1936 n. 83)
            </small>
          </p>
          <p>
            <small>
              AXA MPS Assicurazioni Vita S.p.A. - Sede Legale: Via Aldo Fabrizi n. 9, 00128 Roma – Italia
              Tel.: (+39) 06 50870.1, Fax: (+39) 06 50870295 - PEC: axampsvita@legalmail.it
              Capitale Sociale € 569.000.000 interamente versato - Codice Fiscale e numero iscrizione al Registro delle
              Imprese di Roma: 01075910586, P. I.V.A. GRUPPO IVA AXA ITALIA n. 10534960967, Numero R.E.A. della
              C.C.I.A.A. di Roma 383155 - Autorizzata all’esercizio delle Assicurazioni con Decreto del Ministero
              dell’Industria, del Commercio e dell’Artigianato del 24/7/1974 (G.U. 8/8/1974 n. 208). Compagnia iscritta
              alla Sez. I dell’Albo delle Imprese IVASS al n. 1.00046. La Società è soggetta all’attività di direzione e
              coordinamento di AXA Assicurazioni S.p.A. ed appartiene al Gruppo assicurativo AXA ITALIA, iscritto
              all’Albo dei Gruppi assicurativi con il n. 041.
            </small>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Infografica from "@/components/partials/Infografica";
import CarouselHome from "@/components/partials/CarouselDefault";
import faqDropdown from "@/components/partials/SelectDropdown";
import {mapActions} from "vuex";

export default {
  name: "homeBody",
  components: {
    Infografica,
    CarouselHome,
    faqDropdown
  },
  data() {
    return {
      idSession: sessionStorage.getItem('sessionId'),
      originUrl: typeof window !== 'undefined' ? `${window.location.origin}/` : '',
      originUrlWithoutProtocol: typeof window !== 'undefined' ? window.location.origin.replace(/^https?:\/\//, '') : ''

    };
  },
  methods: {
    ...mapActions({
      'actionClearQuotation': 'params/actionClearQuotation',
      'actionInsType': 'params/actionInsType',
      'actionCustomerType': 'params/actionCustomerType'
    }),
    tracciamento() {
      window.tc_events_global(this.$el, 'xt_click', {
        'level_2_number': 'AXA_PerNoi',
        'click_name': 'Cosa_sogni',
        'click_type': sessionStorage.getItem('sessionId')
      });
      this.actionInsType(null);
      this.actionCustomerType('');
      this.actionClearQuotation();
      this.$router.push('/chi_sei');
    },
  }
};
</script>
